export const environment = {
  securityUrl: 'https://qa.auth.unihelper.com/',
  apiUrl: 'https://qa.api.pms.unihelper.com/',
  production: false,
  currency: 'GBP',
  firebaseConfig : {
    apiKey: 'AIzaSyDcipqu8yz6y7q7eZ8laVh8j-lcRPvNY6Q',
    authDomain: 'unihelpernotification.firebaseapp.com',
    projectId: 'unihelpernotification',
    storageBucket: 'unihelpernotification.appspot.com',
    messagingSenderId: '642020065257',
    appId: '1:642020065257:web:bea916298d66170bc49578',
    measurementId: 'G-L413JECFZP'
  }
};
